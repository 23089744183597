import * as React from "react"
import { graphql } from "gatsby"
import PageHeading from "../components/page-heading"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink"

import Layout from "../components/layout"
import Seo from "../components/seo"

import portfolioData from "../data/projects.json"

const PortfolioIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Portfolio" />
      <div className="flex">
        <div className="m-auto w-3/4">
          <PageHeading>
            <p className="font-nunito text-xl">
              Here's a few of my projects. Please check out my{" "}
              <a
                href="https://github.com/JasonFritsche"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>{" "}
              for more.
            </p>
          </PageHeading>
        </div>
      </div>
      <div className="flex flex-wrap justify-evenly">
        {portfolioData.map(project => (
          <div
            key={project.id}
            className="flex flex-col w-full lg:w-[28%] m-2 border-2 drop-shadow-sm hover:drop-shadow-md hover:shadow-sm rounded dark:border-zinc-500 mt-4"
          >
            <div className="border-b-2 dark:bg-slate-600 dark:border-zinc-500 hover:dark:bg-slate-700">
              <h2 className="my-4 text-base text-center dark:text-slate-200 font-medium hover:cursor-default">
                {project.name}
              </h2>
            </div>
            <div className="flex flex-row flex-wrap">
              {project.built_with.map((item, idx) => (
                <div
                  key={idx}
                  className="badge mx-2 badge-primary font-nunito mt-2"
                >
                  {item}
                </div>
              ))}
            </div>
            <div className="p-2 font-nunito text-base text-slate-800">
              <p>{project.description}</p>
            </div>
            <div className="mt-auto border-t-2 dark:border-zinc-500 dark:bg-slate-600 hover:dark:bg-slate-700">
              <a
                href={project.links.repo}
                target="_blank"
                rel="noopener noreferrer"
                data-tip="View Repo"
                className="tooltip tooltip-primary mr-3 p-1 text-4xl text-slate-700 dark:text-slate-300 hover:dark:text-slate-400 hover:cursor-pointer"
              >
                <FaGithub />
              </a>
              <a
                href={project.links.site}
                target="_blank"
                rel="noopener noreferrer"
                data-tip="View Site"
                className="tooltip tooltip-primary mr-2 p-1 text-4xl text-slate-700 dark:text-slate-300 hover:dark:text-slate-400 hover:cursor-pointer"
              >
                <FiExternalLink />
              </a>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default PortfolioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
